import WidgetWrapper from '../WidgetWrapper';
import { TagItem } from 'src/features/sites/components/tag-item';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { PositioningTypes } from 'src/services';
import { Dialog, useTheme } from '@mui/material';
import { useAuthentication } from 'src/features/authentication/context';
import ModalEditLiveDataWidget from '../ModalEditLiveDataWidget/ModalEditLiveDataWidget';

type Props = {
  title: string;
  isDataReady: boolean;
  selectedTagIds?: { id: string; position: number }[];
  widgetId?: string;
  toggleAddOrRemoveHandler?: () => void;
  testId?: string;
  isEditMode: boolean;
  messages?: any;
  isMobile: boolean;
  pos: PositioningTypes;
  gatewayInputs: any;
};

export const LiveDataWidget = ({
  title,
  isDataReady,
  widgetId,
  toggleAddOrRemoveHandler,
  testId,
  isEditMode,
  messages,
  isMobile,
  pos,
  gatewayInputs,
  selectedTagIds,
}: Props) => {
  const [liveData, setLiveData] = useState<any>([]);
  const [isLiveDataModalOpen, setIsLiveDataModalOpen] = useState<boolean>(false);

  const theme = useTheme();
  const {
    siteId: { value: siteId },
  } = useAuthentication();

  const tags = useMemo(() => {
    const filteredTags = gatewayInputs?.filter((item: any) =>
      selectedTagIds?.some((tag) => tag.id === item.id)
    );

    return filteredTags?.sort((a: any, b: any) => {
      const posA = selectedTagIds?.find((tag) => tag.id === a.id)?.position || 0;
      const posB = selectedTagIds?.find((tag) => tag.id === b.id)?.position || 0;
      return posA - posB;
    });
  }, [gatewayInputs, selectedTagIds]);

  const formttedTags = useMemo(
    () => tags.map((item: any) => ({ tagId: item.id, ...item })),
    [tags]
  );
  const onCloseEditDialog = useCallback(() => {
    if (setIsLiveDataModalOpen) setIsLiveDataModalOpen(false);
  }, []);

  useEffect(() => {
    if (messages?.data) {
      try {
        setLiveData((prevState: any) => {
          const existingGateway = prevState.find(
            (item: any) => item.data.gatewayId === messages.data.gatewayId
          );
          if (existingGateway && messages?.data.gatewayInputs?.length === 1) {
            const inputToUpdate = messages.data.gatewayInputs[0];

            const inputIndex = existingGateway.data.gatewayInputs.findIndex(
              (input: any) => input.id === inputToUpdate.id
            );
            if (inputIndex !== -1) {
              const updatedGateway = {
                ...existingGateway,
                gatewayInputs: [
                  ...existingGateway.data.gatewayInputs.slice(0, inputIndex),
                  inputToUpdate,
                  ...existingGateway.data.gatewayInputs.slice(inputIndex + 1),
                ],
              };
              return [
                ...prevState.filter((item: any) => item.data.gatewayId !== messages.data.gatewayId),
                updatedGateway,
              ];
            }
          }

          return [
            ...prevState.filter((item: any) => item.data.gatewayId !== messages.data.gatewayId),
            messages,
          ];
        });
      } catch (error) {
        console.error('Error parsing gateway_inputs:', error);
      }
    }
  }, [messages]);

  const liveDataForSite = useMemo(
    () => liveData?.filter((item: any) => item.data.siteId === siteId) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [liveData]
  );
  const combinedInputs = useMemo(
    () => liveDataForSite.reduce((acc: any, item: any) => acc.concat(item.data.data), []),
    [liveDataForSite]
  );

  const liveDataComplete = useMemo(
    () => ({
      siteId: undefined,
      tags: combinedInputs,
    }),
    [combinedInputs]
  );

  return (
    <>
      <Dialog
        onClose={onCloseEditDialog}
        open={isLiveDataModalOpen}
        PaperProps={{
          sx: { backgroundColor: theme.palette.background.default },
        }}
      >
        <ModalEditLiveDataWidget
          title={title}
          widgetId={widgetId}
          pos={pos}
          toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
          onClose={onCloseEditDialog}
          formttedTags={formttedTags}
          isDataTagsReady={isDataReady}
          gatewayInputs={gatewayInputs}
        />
      </Dialog>
      <WidgetWrapper
        title={title}
        isDataReady={isDataReady}
        isLiveDataWidget={true}
        widgetId={widgetId}
        toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
        isEditMode={isEditMode}
        testId={testId}
        setIsLiveDataModalOpen={setIsLiveDataModalOpen}
      >
        {formttedTags?.map((tag: any) => (
          <TagItem
            testId={`tag-item-${tag.id}`}
            key={tag.id}
            tag={tag}
            isMobile={isMobile}
            liveDataComplete={liveDataComplete}
            isLiveDataWidget={true}
          />
        ))}
      </WidgetWrapper>
    </>
  );
};
