import Router from './routes';
import { useTheme } from '@mui/material';
import { useEffect, useLayoutEffect } from 'react';
import { customScrollbar } from './styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';

export default function App() {
  const theme = useTheme();
  useLayoutEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.paper;
  });
  const { client } = useClientAsyncInit(process.env.REACT_APP_STATSIG_KEY || '', {
    userID: 'anonymous_' + Math.random().toString(36).substring(7),
  });

  const queryClient = new QueryClient();

  useEffect(() => {
    const styleElement = document.createElement('style');
    const selectors = ['body', '.custom-autocomplete-scrollbar'];
    selectors.forEach((selector) =>
      styleElement.appendChild(document.createTextNode(customScrollbar(selector, theme)))
    );
    document.head.appendChild(styleElement);
  }, [theme]);

  return (
    <StatsigProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </StatsigProvider>
  );
}
