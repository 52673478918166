import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { StopAlaramIcon, CheckmarkCircleFilledLargeIcon } from 'src/assets';
import { StateLabelOnBox, StateLabelOffBox } from 'src/components';
import { StyledItemText, StyledTagItem } from './style';
import { TagType } from '../../types/types';
import { LabelTagBox } from '../label-tag-box';
import { convertSecondsToHours } from 'src/utilities';

type Props = {
  tag: TagType;
  isMobile: boolean;
  testId: string;
  liveDataComplete: any;
  isLiveDataWidget?: boolean;
};
export const TagItem = ({ tag, isMobile, testId, liveDataComplete, isLiveDataWidget }: Props) => {
  const liveDataTag = liveDataComplete?.tags?.find((item: TagType) => item.id === tag.tagId);

  const theme = useTheme();
  const isDataInSeconds = tag.rawUnit === 's';
  const greenColor = theme.palette.success.main;
  const redColor = theme.palette.error.main;
  const tagValue = Number(liveDataTag?.value || tag?.value);
  const displayName = liveDataTag?.name || tag?.displayName;
  const rawUnit = liveDataTag?.rawUnit || tag?.rawUnit;
  const displayedRawUnit = isDataInSeconds ? '(h)' : rawUnit && `(${rawUnit})`;

  //State tag
  const stateTagOn = liveDataTag?.indicator === 'StateOn' || tag?.indicator === 'StateOn';
  const stateTagOff = liveDataTag?.indicator === 'StateOff' || tag?.indicator === 'StateOff';

  //Alarm tag
  const alarmTagOn = liveDataTag?.indicator === 'AlarmOn' || tag?.indicator === 'AlarmOn';
  const alarmTagOff = liveDataTag?.indicator === 'AlarmOff' || tag?.indicator === 'AlarmOff';

  //Alarm priority tag
  const alarmPriorityTag = liveDataTag?.indicator || tag?.indicator;
  const isNotRestOfTheTags = !stateTagOn && !stateTagOff && !alarmTagOn && !alarmTagOff;

  const numberOfAllowedChars = () => {
    if (isMobile) {
      if (isLiveDataWidget) {
        return window.screen.width > 500 ? 65 : 22;
      }
      return 18;
    }

    if (isLiveDataWidget) {
      return window.screen.width < 1000 ? 18 : 28;
    }

    return 35;
  };

  const displayNameAndRawUnit = `${displayName}${displayedRawUnit ? `${displayedRawUnit}` : ''}`;
  const displayNameLength = displayNameAndRawUnit?.length;

  let ComponentToRender = null;

  if (stateTagOn) {
    ComponentToRender = <StateLabelOnBox />;
  } else if (stateTagOff) {
    ComponentToRender = <StateLabelOffBox />;
  } else if (alarmTagOff) {
    ComponentToRender = (
      <Box data-sm={`checkmark-icon-${tag.tagId}`}>
        <CheckmarkCircleFilledLargeIcon fill={greenColor} width={16} height={16} />
      </Box>
    );
  } else if (alarmTagOn) {
    ComponentToRender = (
      <Box data-sm={`stop-icon-${tag.tagId}`}>
        <StopAlaramIcon width={16} height={16} fill={redColor} />
      </Box>
    );
  } else if (isNotRestOfTheTags && alarmPriorityTag) {
    ComponentToRender = <LabelTagBox alarmPriority={alarmPriorityTag} value={tagValue} />;
  } else {
    ComponentToRender = (
      <LabelTagBox
        alarmPriority="null"
        value={
          !isDataInSeconds ? tagValue : Number(convertSecondsToHours(tagValue, 2).slice(0, -1))
        }
      />
    );
  }

  return (
    <StyledTagItem
      theme={theme}
      isMobile={isMobile}
      data-testid={testId}
      isLiveData={isLiveDataWidget}
    >
      <Tooltip
        title={displayNameAndRawUnit}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: '#fff',
            },
          },
        }}
      >
        <StyledItemText isMobile={isMobile} isLiveData={isLiveDataWidget}>
          {displayNameAndRawUnit.slice(0, numberOfAllowedChars())}
          {displayNameLength > numberOfAllowedChars() && (
            <Typography component="span">... </Typography>
          )}
        </StyledItemText>
      </Tooltip>

      {ComponentToRender}
    </StyledTagItem>
  );
};
